import Siema from 'siema';
const siemas = document.querySelectorAll('.siema');

for (let i = 0; i < siemas.length; i++) {
  let siema = new Siema({
    selector: siemas[i],
  })
  siemas[i].parentNode.querySelector('#prev').addEventListener('click', () => siema.prev());
  siemas[i].parentNode.querySelector('#next').addEventListener('click', () => siema.next()); 
}
